<button
  type="button"
  class="btn btn-secondary text-white"
  data-bs-toggle="modal"
  [attr.data-bs-target]="'#staticAvatarBackdrop' + name"
>
  Selecciona un avatar
</button>
<span class="mx-3" *ngIf="avatar.url">
  <img
    class="img-fluid bg-info cursor-pointer rounded-pill p-1"
    [src]="avatar.url"
    [alt]="avatar.id"
  />
</span>
<span class="mx-3 text-danger fw-bold" *ngIf="!avatar.url">
  Debe elegir un Avatar
</span>

<div
  class="modal fade"
  [attr.id]="'staticAvatarBackdrop' + name"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticAvatarBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticAvatarBackdropLabel">
          Avatares
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div
            class="col-6 col-md-3 mb-2"
            *ngFor="let avatar of avatars; index as i"
          >
            <img
              class="img-fluid bg-info cursor-pointer rounded-pill p-1"
              [ngClass]="getAvatar(i) || ''"
              [src]="avatar.url"
              [alt]="avatar.id"
              (click)="setAvatar(avatar.id, avatar.url, i)"
            />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button data-bs-dismiss="modal" type="button" class="btn btn-primary">
          Seleccionar
        </button>
      </div>
    </div>
  </div>
</div>
