import { Component, Input, OnInit } from "@angular/core";
import { avatarsData } from "./avatar.data";

@Component({
  selector: "zafe-avatar-modal",
  templateUrl: "./avatar-modal.component.html",
  styleUrls: ["./avatar-modal.component.scss"],
})
export class AvatarModalComponent implements OnInit {
  @Input() index: number = 0;
  @Input() isMinor: boolean = false;

  private _avatars: any[] = [];
  private _avatar = {
    id: -1,
    url: "",
    index: -1,
  };

  constructor() {}

  ngOnInit(): void {
    this._avatars = avatarsData.avatars;
  }

  get avatar() {
    return this._avatar;
  }

  get avatars() {
    return this._avatars;
  }

  getAvatar(index: number) {
    return this._avatar.index == index && "active";
  }

  get name() {
    return this.isMinor ? "Minor" + this.index : this.index;
  }

  setAvatar(id: number, url: string, index: number) {
    const avatar = { id, url, index };

    localStorage.setItem(
      this.isMinor
        ? `actualMinorAvatar${this.index}`
        : `actualAvatar${this.index}`,
      JSON.stringify(avatar)
    );

    this._avatar = avatar;
  }
}
