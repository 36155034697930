import { Injectable } from "@angular/core";
import {
  Auth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  signInWithEmailAndPassword,
} from "@angular/fire/auth";
import { Database, objectVal, onValue, ref, set } from "@angular/fire/database";
import {
  doc,
  docSnapshots,
  Firestore,
  onSnapshot,
} from "@angular/fire/firestore";
import Swal from "sweetalert2";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "any",
})
export class AuthService {
  constructor(
    private readonly router: Router,
    private readonly auth: Auth,
    private db: Database,
    private readonly firestore: Firestore,
  ) {}

  currentUser() {
    return this.auth.currentUser;
  }

  currentToken() {
    return this.currentUser()?.getIdToken(true);
  }

  searchUser(uid: string) {
    const doc = ref(this.db, `users/${uid}`);

    return objectVal(doc);
  }

  saveData(uid: string, newData: any) {
    const doc = ref(this.db, `/users/${uid}`);

    return onValue(doc, (snapshot) => {
      const actualData = snapshot.val();

      set(doc, { ...actualData, ...newData });
    });
  }

  verifyZafers(uid: string, user: any) {
    if (!user) {
      this.logout();
      return;
    }

    const docRef = doc(this.firestore, `zafers/${uid}`);

    onSnapshot(docRef, (res) => {
      const info: any = res.data();

      if (!info || !info.active) {
        Swal.fire(
          "No autorizado",
          "No se encuentra autorizado para entrar, solicite un acceso.",
          "error",
        ).then(() => {
          this.logout();
        });

        return;
      }

      this.saveUser(JSON.stringify(user));
      this.router.navigate(["/welcome"]);
    });
  }

  loginWithEmailAndPassword(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  loginWithGoogle() {
    return signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  logout() {
    return signOut(this.auth);
  }

  saveUser(user: string) {
    localStorage.setItem("user", user);
  }

  clearUser() {
    localStorage.clear();
  }
}
