<button
  type="button"
  class="btn btn-secondary text-white"
  data-bs-toggle="modal"
  [attr.data-bs-target]="'#staticRoleBackdrop' + name"
>
  Selecciona un Rol
</button>
<span *ngIf="role" class="mb-3 text-info fw-bolder"> {{ role }} </span>
<span class="mx-3 text-danger fw-bold" *ngIf="!role"> Debe elegir un Rol </span>

<div
  class="modal fade"
  [attr.id]="'staticRoleBackdrop' + name"
  [attr.data-bs-backdrop]="'static'"
  [attr.data-bs-keyboard]="false"
  tabindex="-1"
  aria-labelledby="staticRoleBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticRoleBackdropLabel">Roles</h1>
        <button
          type="button"
          class="btn-close"
          [attr.data-bs-dismiss]="'modal'"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div *ngFor="let role of roles" class="col-12 col-md-6 g-2">
            <button
              type="button"
              [attr.data-bs-dismiss]="'modal'"
              class="btn btn-primary w-100"
              (click)="selectRole(role)"
            >
              {{ role.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
