<div class="container my-5 p-5 text-center bg-white rounded">
  <div class="d-flex justify-content-between flex-wrap my-3">
    <p>
      {{ version }}

      <span class="sandbox" *ngIf="sandbox"> Sandbox </span>
    </p>
    <div *ngIf="isHome">
      <button
        (click)="cancelSale()"
        class="btn btn-danger text-white fw-bold w-auto"
      >
        Cancelar Venta <i class="bi bi-x-lg fw-bold"></i>
      </button>
    </div>
    <div *ngIf="isLogout">
      <b>Cerrar Sesión </b>
      <button
        (click)="logout()"
        class="btn btn-danger text-white fw-bold w-auto"
      >
        <i class="bi bi-box-arrow-in-right"></i>
      </button>
    </div>
    <div *ngIf="isBack">
      <a
        routerLink="/groups"
        [queryParams]="{ members: members }"
        class="btn btn-dark fw-bold w-auto"
      >
        <i class="bi bi-arrow-left"></i> Volver
      </a>
    </div>
  </div>
  <div class="mb-5">
    <img
      class="img-fluid"
      src="../../assets/SVG/login.svg"
      width="250"
      alt="Login-Icon.png"
    />
  </div>
  <router-outlet></router-outlet>

  <zafe-update-prompt *ngIf="!validateVersion && isUpdateAvailable" />
  <!-- <zafe-update-prompt /> -->
</div>
