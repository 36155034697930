<h5>Últimas citas</h5>

<ol class="list-group list-group-numbered">
  <li
    *ngIf="lastAppointment.length === 0; else appointmentTemplate"
    class="list-group-item d-flex justify-content-between align-items-start"
  >
    <div class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </li>
  <ng-template #appointmentTemplate>
    <li
      class="list-group-item d-flex justify-content-between align-items-start"
      *ngFor="let appointment of lastAppointment"
      (click)="selectAppointment(appointment.id)"
    >
      <div class="ms-2 me-auto">
        <div class="fw-bold">
          {{ appointment.name }} {{ appointment.lastname }}
        </div>
        {{ appointment.email }}
      </div>
      <i class="bi bi-arrow-right-circle h4"></i>
    </li>
  </ng-template>
</ol>
