<div class="row">
  <div
    *ngFor="let appointment of pendingAppointment"
    class="col-12 col-md-4 my-2"
  >
    <div class="card text-start">
      <div class="card-body">
        <h5 class="card-title">
          {{ appointment.name }} {{ appointment.lastname }}
        </h5>
        <p class="card-text">{{ appointment.email }}</p>
        <button
          class="btn btn-outline-danger w-100 d-flex justify-content-between"
          (click)="continuePendingSale(appointment.id)"
        >
          Cerrar
          <i class="bi bi-arrow-right-circle"></i>
        </button>
      </div>
    </div>
  </div>
</div>
