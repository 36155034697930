import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

import { debounceTime, distinctUntilChanged } from "rxjs";
import Swal from "sweetalert2";

import { SalesService } from "apps/onboarding/src/app/core/services/sales.service";

@Component({
  selector: "zafe-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  private _searchResults: any[] = [];

  searchForm = this.fb.group({
    search: [""],
  });

  constructor(
    private readonly fb: FormBuilder,
    private readonly salesService: SalesService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.searchSales();
  }

  searchSales() {
    const search = this.searchForm.controls.search;

    search.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        if (!value) {
          this._searchResults = [];
          return;
        }

        this.salesService
          .searchAppointments(value.trim().toLowerCase())
          .subscribe((res) => {
            console.log(res);
            this._searchResults = res;
          });
      });
  }

  async selectAppointment(id: string) {
    const result = await Swal.fire({
      title: "¿Está seguro que desea cerrar la venta?",
      text: "Esta acción no se puede deshacer",
      confirmButtonText: "Si, Cerrar venta",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      icon: "info",
    });

    if (result.isConfirmed) {
      this.salesService.changeAppointmentStatusPending(id).subscribe({
        next: (res) => {
          localStorage.setItem("saleId", id);
          this.router.navigateByUrl("/validate/box");
        },
        error: async (error) => {
          console.log(error);

          await Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Algo salión mal",
            showConfirmButton: false,
            showCloseButton: true,
          });
        },
      });
    }
  }

  get searchResults() {
    return this._searchResults;
  }
}
