<form [formGroup]="searchForm">
  <div class="form-group">
    <label class="form-label"><strong>Buscar</strong></label>
    <input
      formControlName="search"
      type="search"
      placeholder="Buscar por: Nombre, Apellido o Email"
      class="form-control"
    />
  </div>
</form>

<ol class="list-group list-group-numbered mt-3">
  <div
    *ngIf="searchResults.length === 0; else appointmentTemplate"
    class="d-flex justify-content-between align-items-start text-secondary"
  >
    Sin Resultados
    <i class="bi bi-x"></i>
  </div>
  <ng-template #appointmentTemplate>
    <li
      class="list-group-item d-flex justify-content-between align-items-start"
      *ngFor="let appointment of searchResults"
      (click)="selectAppointment(appointment.id)"
    >
      <div class="ms-2 me-auto">
        <div class="fw-bold">
          {{ appointment.name }} {{ appointment.lastname }}
        </div>
        {{ appointment.email }}
      </div>
      <i class="bi bi-arrow-right-circle h4"></i>
    </li>
  </ng-template>
</ol>
