import { Component } from "@angular/core";
import { Router } from "@angular/router";
import Swal from "sweetalert2";

import { SalesService } from "../../../../core/services/sales.service";

@Component({
  selector: "zafe-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
})
export class ListComponent {
  private _lastAppointment: any[] = [];

  constructor(
    private readonly salesService: SalesService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.getLastAppointment();
  }

  getLastAppointment() {
    this.salesService.getLastAppointments().subscribe((res) => {
      this._lastAppointment = res;
    });
  }

  async selectAppointment(id: string) {
    const result = await Swal.fire({
      title: "¿Está seguro que desea cerrar la venta?",
      text: "Esta acción no se puede deshacer",
      confirmButtonText: "Si, Cerrar venta",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      icon: "info",
    });

    if (result.isConfirmed) {
      this.salesService.changeAppointmentStatusPending(id).subscribe({
        next: (res) => {
          localStorage.setItem("saleId", id);
          this.router.navigateByUrl("/validate/box");
        },
        error: async (error) => {
          console.log(error);

          await Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Algo salión mal",
            showConfirmButton: false,
            showCloseButton: true,
          });
        },
      });
    }
  }

  get lastAppointment() {
    return this._lastAppointment;
  }
}
